
import { ExhibitionClient } from '@/services/services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/model';

@Options({})
export default class DemoPage extends Vue {

    demos: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    created() {
        ExhibitionClient.getDemos()
        .then(x => {
            this.demos = x;
            this.loaded = true;
        })
    }
    
}
